<template>
  <layout-dashboard :title="title">
    <v-container fluid class="pa-0 mt-0 fill-container-height">
      <v-row class="ma-4" justify="center">
        <v-col cols="12" sm="10" md="8" lg="6" class="py-0 my-0">
          <v-card>
            <v-card-title>
              {{ texts.yourID }}
            </v-card-title>
            <v-card-actions>
              <v-row class="px-2 pb-2" align="center">
                <v-col cols="12" sm="8" md="8" lg="7" xl="6" class="py-0 my-0">
                  <v-text-field
                      clas="py-0 my-0"
                      prepend-inner-icon="mdi-identifier"
                      :value="uid"
                      disabled
                  />
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <create-account @grant="grantAdmin" />

      <grant-access
          @grant="grantAdmin"
          :admins="admins"
      />

      <v-row class="ma-4" justify="center">
        <v-col cols="12" sm="10" md="8" lg="6" class="py-0 my-0">
          <v-card>
            <v-card-title>
              {{  texts.admins }}
            </v-card-title>
            <v-card-subtitle v-if="changedAccessText">{{ changedAccessText }}</v-card-subtitle>
            <v-list>
              <v-list-item
                  :key="admin"
                  v-for="admin in admins"
              >
               <v-row>
                 <v-col cols="9" sm="8" md="7" xl="5" class="py-0 mx-0 text-truncate">
                   {{ admin }}
                 </v-col>
                 <v-col cols="2" class="pt-0 px-0 ma-0">
                   <v-btn
                       :icon="!mdAndUp"
                       :disabled="admins.length <= minAdmins"
                       class="text-none"
                       small
                       outlined color="error"
                       @click="removeAdmin(admin)"
                   >
                     <span v-if="mdAndUp">{{ texts.revoke }}</span>
                     <v-icon small :class="{'ml-1': mdAndUp}">
                       mdi-account-remove
                     </v-icon>
                   </v-btn>
                 </v-col>
               </v-row>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </layout-dashboard>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import firebase from "firebase/app";
import 'firebase/firestore'
import CreateAccount from "@/components/dashboard/admins/CreateAccount";
import GrantAccess from "@/components/dashboard/admins/GrantAccess";
import moment from "moment";

export default {
  name: "Admins",
  components: {GrantAccess, CreateAccount},
  data: () => ({
    title: 'Administrators',
    uid: '',
    changedAccessText: '',
    changedAccess: null,
    admins: [],
    minAdmins: 2,
    texts: {
      yourID: 'Your ID',
      admins: 'Administrators',
      userGranted: 'Access has been granted to',
      revokeAccess: 'access has been revoked',
      revoke: 'Revoke'
    }
  }),
  beforeMount() {
    this.setLoader({
      visible: !this.getAppOverview,
      message: this.loaderMessage
    })
  },
  async mounted() {
    this.uid = firebase.auth().currentUser.uid

    const snap = await firebase.firestore().collection('administrators').get()
    this.admins = snap.docs.map(({id}) => id)
  },
  computed: {
    ...mapGetters('statistics', ['getAppOverview', 'getAllStickers']),
    loaderMessage() {
      return !this.getAppOverview ? this.loadingMessage : ''
    },
    mdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  methods: {
    ...mapMutations('components', ['setLoader']),
    async grantAdmin(uid) {
      try {
        await firebase.firestore().collection('administrators').doc(uid).set({
          grantedBy: firebase.auth().currentUser.uid,
          created: moment().format()
        })

        this.admins.unshift(uid)

        this.changedAccessText = `${this.texts.userGranted} '${uid}'`
        this.changedAccessTimer()
      } catch (e) {
        console.error(e)
      }
    },
    async removeAdmin(uid) {
      if (this.admins.length <= this.minAdmins) {
        return
      }
      try {
        await firebase.firestore().collection('administrators').doc(uid).delete()

        this.admins = this.admins.filter(admin => admin !== uid)

        this.changedAccessText = `'${uid}' ${this.texts.revokeAccess}`
        this.changedAccessTimer()

        if (uid === firebase.auth().currentUser.uid) {
          await firebase.auth().signOut()
          this.$router.push('/login')
        }
      } catch (e) {
        console.error(e)
      }
    },
    changedAccessTimer() {
      clearTimeout(this.changedAccess)
      this.changedAccess = setTimeout(() => this.changedAccessText = '', 5000)
    }
  },
  watch: {
    getAppOverview(value) {
      this.setLoader({visible: !value, message: this.loaderMessage})
    }
  }
}
</script>
