export default {
    required: (value) => {
        const requiredText = 'Field is required'

        if (value === null) {
            return requiredText
        }
        return value.trim() !== '' || requiredText
    },
    password: (value) => (value.length >= 6 || 'Password is too short')
}