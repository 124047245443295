<template>
  <v-row class="ma-4" justify="center">
    <v-col cols="12" sm="10" md="8" lg="6" class="py-0 my-0">
      <v-card>
        <v-card-title>
          {{ texts.addAdmin }}
        </v-card-title>
        <v-card-actions>
          <v-row class="px-2 pb-2">
            <v-col cols="12" sm="8" md="8" lg="7" xl="6">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                    class="py-0 my-0"
                    v-model="newAdmin"
                    :placeholder="texts.uid"
                    :rules="[unique, validators.required]"
                />
                <v-btn
                    class="text-none mt-1"
                    color="primary"
                    :disabled="!valid || !newAdmin"
                    @click="addAdmin"
                >
                  {{ texts.grant }}
                  <v-icon small class="ml-1">
                    mdi-account-key
                  </v-icon>
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import validators from '@/utils/validators'

export default {
  name: 'GrantAccess',
  data: () => ({
    texts: {
      addAdmin: 'Add administrator',
      uid: 'uid',
      grant: 'Grant',
      rules: {
        exist: 'User is already administrator',
      }
    },
    newAdmin: '',
    valid: true,
    validators,
  }),
  props: {
    admins: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    addAdmin() {
      if (!this.$refs.form.validate()) {
        return
      }

      const admin = this.newAdmin.trim()

      this.$emit('grant', admin)

      this.$refs.form.reset()
    },
    unique(value) {
      return !this.admins.includes(value?.trim()) || this.texts.rules.exist
    }
  }
}
</script>
