<template>
  <v-row class="ma-4" justify="center">
    <v-col cols="12" sm="10" md="8" lg="6" class="py-0 my-0">
      <v-card>
        <v-card-title>
          {{ texts.createAccount }}
        </v-card-title>
        <v-card-text v-if="error">{{ error }}</v-card-text>
        <v-card-actions>
          <v-row class="px-2 pb-2">
            <v-col cols="12" sm="8" md="8" lg="7" xl="6">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                    class="py-0 my-0"
                    v-model="email"
                    prepend-inner-icon="mdi-at"
                    :placeholder="texts.email"
                    :rules="[validators.required]"
                />
                <v-text-field
                    class="py-0 my-0"
                    v-model="password"
                    prepend-inner-icon="mdi-key"
                    :placeholder="texts.password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPassword = !showPassword"
                    :type="showPassword ? 'text' : 'password'"
                    :rules="[validators.required, validators.password]"
                />
                <v-checkbox
                  class="py-0 my-0"
                  v-model="isAdmin"
                  :label="texts.isAdmin"
                />
                <v-btn
                    class="text-none mt-1"
                    color="primary"
                    :disabled="isFormValid"
                    @click="createAccount"
                >
                  {{ texts.create }}
                  <v-icon small class="ml-1">
                    mdi-account-plus
                  </v-icon>
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>
import validators from '@/utils/validators'
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: 'CreateAccount',
  data: () => ({
    texts: {
      createAccount: 'Create account',
      isAdmin: 'Administrator',
      email: 'Email',
      password: 'Password',
      create: 'Create'
    },
    validators,
    valid: true,
    email: '',
    password: '',
    showPassword: false,
    isAdmin: false,
    error: ''
  }),
  computed: {
    isFormValid() {
      return !this.valid || !this.email || !this.password
    }
  },
  methods: {
    async createAccount() {
      if (!this.$refs.form.validate()) {
        return
      }
      let secondaryApp

      try {
        secondaryApp = firebase.initializeApp(this.$firebaseConfig, 'secondary')
        const {user} = await secondaryApp.auth().createUserWithEmailAndPassword(this.email, this.password)
        await secondaryApp.auth().signOut()

        if (this.isAdmin) {
          this.$emit('grant', user.uid)
        }
      } catch(e) {
        this.error = e
        setTimeout(() => this.error = '', 5000)
      } finally {
        await secondaryApp?.delete()
      }
      if (!this.error) {
        this.$refs.form.reset()()
      }
    }
  }
}
</script>
